<template>
  <RouterLink  v-for="item in goods" :key="item.uid" :to="`/product/${item.uid}`" class='goods-item'>
    <div >
        <img v-lazy="item.image" alt="">
      <div class="name ellipsis">{{item.name}}</div>
      <!-- <p class="desc ellipsis">{{item.desc}}</p> -->
      <p class="price">&yen;{{item.price}}</p>
    </div>
  </RouterLink>
  <!-- <a v-else href="javascript:;" class='goods-item'>
    <img v-lazy="goods.image" alt="">
    <p class="name ellipsis">{{goods.name}}</p>
    <p class="desc ellipsis">{{goods.desc}}</p>
    <p class="price">&yen;{{goods.price}}</p>
  </a> -->
</template>

<script>
export default {
  name: 'GoodsItem',
  props: {
    goods: {
      type: Array,
      default: () => ([])
    },
    isLink: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang='less'>
.goods-item {
  display: flex;
  justify-content: space-between;
  width: 220px;
  padding: 20px 30px;
  text-align: center;
  .hoverShadow();
  img {
    width: 160px;
    height: 160px;
  }
  p {
    padding-top: 10px;
  }
  .name {
    font-size: 16px;
    width: 180px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .desc {
    color: #999;
    height: 29px;
  }
  .price {
    color: @priceColor;
    font-size: 20px;
  }
}
</style>
